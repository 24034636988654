<template>
	<page>
		<module>
			<c-title>基本信息</c-title>
			<c-detail column="2" style="max-width:1100px;">
				<c-detail-item label="委外订单号">
					{{detailInfo.iconsigned_order_number}}
				</c-detail-item>
				<c-detail-item label="委外类型">
					{{detailInfo.title}}
				</c-detail-item>
				<c-detail-item label="委托方">
				     {{detailInfo.manufacture_name}}
				</c-detail-item>
				<c-detail-item label="委托方联系人">
				     {{detailInfo.manufacture_contacts}}/{{detailInfo.manufacture_phone}}
				</c-detail-item>
				<c-detail-item label="负责员工">
				    {{detailInfo.salesman_name}}
				</c-detail-item>
				<c-detail-item label="关联订单">
				    {{detailInfo.order_number}}
				</c-detail-item>
				<c-detail-item label="交货日期">
				    {{detailInfo.delivery_schedule_date}}
				</c-detail-item>
				<c-detail-item label="订单备注" colspan="2">
					{{detailInfo.order_remark}}
				</c-detail-item>
			</c-detail>
			<c-title>生产产品</c-title>
			<c-detail style="max-width:1100px;">
				<c-table ref="product_table" :data="productData" :paging="false" height="300" border>
<!--					<c-table-column
						label="产品编码"
						name="product_code"
						width="100"
					>
					</c-table-column>-->

					<c-table-column
						label="产品名称"
						name="product_name"
						width="150"
					>
					</c-table-column>

					<c-table-column
						label="产品颜色"
						name="product_spec"
						width="150"
					>
						<template #default="{data}">
							<span v-if="data.product_spec">{{data.product_spec}}</span>
							<span v-else>--</span>
						</template>
					</c-table-column>

					<c-table-column
						label="产品类型"
						name="product_type"
						width="80"
					>
						<template #default="{data}">
							<span v-if="data.product_type==1">成品</span>
							<span v-if="data.product_type==2">物料</span>
						</template>
					</c-table-column>

					<c-table-column
						label="计划生产数量"
						name="product_count"
						width="80"
						required
					>
					</c-table-column>

					<c-table-column
						label="入库数量"
						name="in_storage_count"
						width="80"
						required
					>
					</c-table-column>

					<c-table-column
						label="不良品数"
						name="defective_product_count"
						width="80"
						required
					>
					</c-table-column>

					<c-table-column
						label="单位"
						name="unit"
						width="50"
					>
					</c-table-column>

					<c-table-column
						label="备注"
						name="remark"
						width="150"
					>
					</c-table-column>

				</c-table>
			</c-detail>
			<c-title>物料清单</c-title>
			<c-detail style="max-width:1100px;">
				<c-table ref="material_table" :data="materialData" :paging="false" height="300" border>
<!--					<c-table-column
						label="物料编码"
						name="product_code"
						width="100"
					>
					</c-table-column>-->

					<c-table-column
						label="物料名称"
						name="product_name"
						width="300"
					>
					</c-table-column>

					<c-table-column
						label="单位"
						name="unit"
						width="50"
					>
					</c-table-column>

					<c-table-column
						label="物料数量"
						name="product_count"
						width="80"
						required
					>
					</c-table-column>

					<c-table-column
						label="已出库数量"
						name="out_storage_count"
						width="80"
						required
					>
					</c-table-column>

					<c-table-column
						label="备注"
						name="remark"
						width="150"
					>
					</c-table-column>

				</c-table>
			</c-detail>
			<template slot="button">
				<c-button v-if="auth('b_iconsigned_order_edit') && detailInfo.state == -2" size="form" @click="navigateTo(`/iconsigned/order/edit/${detailInfo.id}`)">编辑</c-button>
			</template>
		</module>
	</page>
</template>navigateTo(''+data.id)

<script>
	export default {
		name: 'm_iconsigned_order_view',
		
		components: {
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				detailInfo: '',
				productData: [],
				materialData: []
			}
		},
		computed: {
		},

		watch: {
			id() {
				this.productData = [];
				this.materialData = [];
				this.getOrderDetail();
			}
		},

		mounted() {
			if(this.id){
				this.getOrderDetail();
			}
		},

		methods: {
			//查询订单详情
			getOrderDetail() {
				this.request({
					url: '/wms/iconsigned/order/detail',
					data: {
						id:this.id
					},
					loading: true,
					success: async data => {
						this.detailInfo = data;
						this.productData = data.product_list||[];
						this.materialData = data.material_list||[];
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
</style>